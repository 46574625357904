<template>
  <div id="data-list-list-view" class="data-list-container">
    <div v-if="loading" class="loading-pagination">
      <SpinnerColor />
    </div>
    <vs-table
      ref="table"
      multiple
      v-model="selected"
      noDataText=""
      :max-items="credentials.itemsPerPage"
      :data="data"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between w-full"
        v-if="hasData"
      >
        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            style="
              font-family: 'Cairo', sans-serif !important;
              padding: 0.75rem !important;
              border-radius: 0.25rem !important;
            "
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage }} -
              {{ credentials.itemsPerPage * credentials.pageNum }}
              {{ $t("of") }} {{ feed_count }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <ul style="min-width: 8rem">
              <vs-dropdown-item
                @click="credentials.itemsPerPage = 10"
                class="m-0 p-0"
              >
                <li class="flex py-4 px-4 cursor-pointer profile-drop">
                  <span>10</span>
                </li>
              </vs-dropdown-item>
              <vs-divider class="m-0" />
              <vs-dropdown-item
                @click="credentials.itemsPerPage = 20"
                class="m-0 p-0"
              >
                <li class="flex py-4 px-4 cursor-pointer profile-drop">
                  <span>20</span>
                </li>
              </vs-dropdown-item>
              <vs-divider class="m-0" />
              <vs-dropdown-item
                @click="credentials.itemsPerPage = 50"
                class="m-0 p-0"
              >
                <li class="flex py-4 px-4 cursor-pointer profile-drop">
                  <span>50</span>
                </li>
              </vs-dropdown-item>
              <vs-divider class="m-0" />
              <vs-dropdown-item
                @click="credentials.itemsPerPage = 100"
                class="m-0 p-0"
              >
                <li class="flex py-4 px-4 cursor-pointer profile-drop">
                  <span>100</span>
                </li>
              </vs-dropdown-item>
            </ul>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th :key="index" v-for="(head, index) in header" :sort-key="head">{{
          $t(head)
        }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody v-if="hasData">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :key="index" v-for="(td, index) in body">
              <p class="product-due_date font-medium truncate">{{ tr[td] }}</p>
              <vs-chip
                class="product-sender"
                style="font-size: 0.5625rem"
                v-if="tr[td] == null"
                >لا يوجد</vs-chip
              >
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination
      v-if="hasData"
      :total="totalPage"
      v-model="credentials.pageNum"
      :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
      :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
    />
    <empty-data v-if="!hasData" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import SpinnerColor from "./SpinnerColor2.vue";
import emptyTable from "@/mixins/datatableEmpty.js";
import EmptyData from "./EmptyData.vue";

export default {
  name: "data-table-order-for-mission",
  mixins: [emptyTable],
  components: {
    SpinnerColor,
    EmptyData,
  },
  props: {
    data: {
      type: Array,
    },
    header: {
      type: Array,
      required: true,
    },
    body: {
      type: Array,
      required: true,
    },
    missionId: {
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      checkSelect: false,
      isMounted: false,
      popupActive: false,
      id: "",
      credentials: {
        itemsPerPage: 10,
        pageNum: 1,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters("dataList", ["feed_count", "order_for_mission"]),
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    allOrders() {
      return this.data;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.allOrders.length;
    },
    totalPage() {
      return Math.ceil(this.feed_count / this.credentials.itemsPerPage);
    },
  },
  watch: {
    "credentials.pageNum": {
      handler() {
        this.updtaeMissions();
      },
      deep: true,
    },
    "credentials.itemsPerPage": {
      handler() {
        this.updtaeMissions();
      },
      deep: true,
    },
    order_for_mission: {
      handler: function () {
        this.tableHasData(this.order_for_mission);
      },
    },
  },
  methods: {
    ...mapActions("dataList", ["orderForMission"]),
    async updtaeMissions() {
      this.loading = true;
      this.credentials.id = this.missionId;
      await this.orderForMission(this.credentials);
      this.loading = false;
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/_dataTable.scss";
</style>
