const allStatus = {
    // STATUS OF MISSION
    sent: 1,
    new: 2,
    forCaptain: 3,
    givenCaptain: 4,
    exhausted: 5,
    cancel: 6,
    exhaustedWithCaptain: 7,
    canceledWithCaptain: 8
}

export default allStatus