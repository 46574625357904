<template>
  <div class="p-6 loading-table" v-if="showPndingAjaxLoad&&!showData">
    <spinner-color />
  </div>
  <div v-else>
    <div v-if="detail_of_mission.id&&showData">
    <vs-alert
      :active="success_api == 0 && messageApi != ''"
      color="danger"
      icon="new_releases"
    >
      <span>{{ messageApi }}</span>
    </vs-alert>
    <vs-alert
      :active="success_api == 1 && messageApi != ''"
      color="success"
      icon="done_all"
    >
      <span>{{ messageApi }}</span>
    </vs-alert>
    <div v class="title-page flex justify-between text-center">
      <div>
        <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
        <h3
          class="font-bold mb-2 ml-3"
          style="display: inline-block; font-size: 1.125rem"
        >
          {{ $t("displayAndEditMission") }}
        </h3>
      </div>
      <div class="flex" style="align-items: center">
        <!-- CHAT MISSION -->
        <vx-tooltip
          :text="$t('startConversation')"
          position="bottom"
          delay=".2s"
        >
          <i class="mr-4 cursor-pointer" @click="toMissionChat()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                id="message-circle"
                d="M21,11.5a8.38,8.38,0,0,1-.9,3.8A8.5,8.5,0,0,1,12.5,20a8.38,8.38,0,0,1-3.8-.9L3,21l1.9-5.7A8.38,8.38,0,0,1,4,11.5,8.5,8.5,0,0,1,8.7,3.9,8.38,8.38,0,0,1,12.5,3H13a8.48,8.48,0,0,1,8,8Z"
                transform="translate(-2 -2)"
                fill="none"
                stroke="#6e6b7b"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </i>
        </vx-tooltip>

        <!-- ACTION - Printer -->
        <vx-tooltip :text="$t('printingAids')" position="bottom" delay=".2s">
          <feather-icon
            @click="
              $router
                .push(`/printMission/${detail_of_mission.id}`)
                .catch(() => {})
            "
            icon="PrinterIcon"
            svgClasses="h-5 w-5"
            class="print-action mr-2"
          />
        </vx-tooltip>

        <!-- Show Msission -->
        <button
          class="edit-btn mr-5"
          v-if="$acl.not.check('isPublicCompany')"
          @click="toMissions()"
        >
          {{ $t("showMission") }}
          <feather-icon
            class="ml-1 font-semibold"
            style="color: #31006f"
            icon="ChevronLeftIcon"
            svgClasses="h-4 w-4"
          ></feather-icon>
        </button>

        <!-- ACTION - DROPDOWN -->
        <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
          <div
            v-if="!isLoad"
            style="font-family: 'Cairo', sans-serif !important"
            class="p-4 download-btn rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
          >
            <feather-icon
              class="mr-1 font-semibold"
              icon="FileIcon"
              svgClasses="h-4 w-4"
            ></feather-icon>
            {{ $t("downloadExcel") }}
            <feather-icon
              class="ml-1 font-semibold"
              icon="ChevronDownIcon"
              svgClasses="h-4 w-4"
            ></feather-icon>
          </div>
          <div
            v-else
            style="
              font-family: 'Cairo', sans-serif !important;
              padding: 0.75rem !important;
            "
            class="p-4 download-btn rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
          >
            <SpinnerColor />
          </div>

          <vs-dropdown-menu>
            <ul style="min-width: 8rem">
              <!-- EXCEL 1 -->
              <vs-dropdown-item>
                <li
                  class="flex py-4 px-4 cursor-pointer profile-drop"
                  @click="onexport()"
                >
                  <feather-icon
                    icon="FileIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>{{ $t("excelAids") }}</span>
                </li>
              </vs-dropdown-item>
              <vs-divider class="m-0" />
              <!-- EXCEL 2 -->
              <vs-dropdown-item>
                <li
                  class="flex py-4 px-4 cursor-pointer profile-drop"
                  @click="onexport(1)"
                >
                  <feather-icon
                    icon="FileIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>{{ $t("excelReturnedOrders") }}</span>
                </li>
              </vs-dropdown-item>
              <vs-divider class="m-0" />
              <!-- EXCEL 3 -->
              <vs-dropdown-item>
                <li
                  class="flex py-4 px-4 cursor-pointer profile-drop"
                  @click="onexport(2)"
                >
                  <feather-icon
                    icon="FileIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>{{ $t("excelDeliveredOrders") }}</span>
                </li>
              </vs-dropdown-item>
              <vs-divider class="m-0" />
              <!-- EXCEL 4 -->
              <vs-dropdown-item>
                <li
                  class="flex py-4 px-4 cursor-pointer profile-drop"
                  @click="onexport(3)"
                >
                  <feather-icon
                    icon="FileIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>{{ $t("excelSecurities") }}</span>
                </li>
              </vs-dropdown-item>
            </ul>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
    </div>
    <div class="line"></div>
    <div
      class="p-6 flex all-inputs"
      style="flex-wrap: wrap; background-color: #fff"
    >
      <div v-if="detail_of_mission.type != 1" class="mission-key">
        <feather-icon
          @click.prevent="keyOfMission()"
          :class="{ 'eye-icon': iconMissionKey == false }"
          style="display: none"
          icon="EyeIcon"
          svgClasses="h-4 w-4"
        ></feather-icon>
        <feather-icon
          @click.prevent="keyOfMission()"
          :class="{ 'eye-icon': iconMissionKey == true }"
          style="display: none"
          icon="EyeOffIcon"
          svgClasses="h-4 w-4"
        ></feather-icon>
        <label class="font-bold">{{ $t("missionKey") }}</label>
        <vx-tooltip
          text="اضغط هنا لإظهار مفتاح المهمة"
          position="bottom"
          delay=".1s"
        >
          <input
            maxlength="6"
            @click.prevent="keyOfMission()"
            :value="detail_of_mission.key"
            :type="missionKey"
            :class="{ 'input-focus': iconMissionKey == true }"
          />
        </vx-tooltip>
      </div>
      <!-- CODE -->
      <vs-input
        :label="$t('code') + ' :'"
        class="mt-5 font-bold"
        :value="detail_of_mission.code"
        disabled
      />

      <!-- TYPE -->
      <vs-input
        :label="$t('type') + ' :'"
        v-if="detail_of_mission.type == 1"
        :value="$t('receive')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('type') + ' :'"
        v-else-if="detail_of_mission.type == 2"
        :value="$t('supply2')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('type') + ' :'"
        v-else-if="detail_of_mission.type == 3"
        :value="$t('receivingAndSupplying')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('type') + ' :'"
        v-else
        :value="$t('returnsOrReceiptsAndReturns')"
        class="font-bold mt-5"
        disabled
      />

      <!-- BRANCH -->
      <vs-input
        :label="$t('branch') + ' :'"
        :value="branch_mission"
        class="font-bold mt-5"
        v-if="$acl.not.check('isPublicCompany')"
        disabled
      />

      <!-- CLIENT -->
      <vs-input
        :label="$t('client') + ' :'"
        :value="client_mission"
        class="font-bold mt-5"
        disabled
      />

      <!-- AREA -->
      <vs-input
        :label="$t('captain') + ' :'"
        :value="captain_mission"
        class="font-bold mt-5"
        v-if="$acl.not.check('isPublicCompany')"
        disabled
      />

      <!-- STATUS -->
      <vs-input
        :label="$t('status') + ' :'"
        v-if="detail_of_mission.status == missionStatus.sent"
        :value="$t('statusSent')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('status') + ' :'"
        v-else-if="detail_of_mission.status == missionStatus.new"
        :value="$t('statusNew')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('status') + ' :'"
        v-else-if="detail_of_mission.status == missionStatus.forCaptain"
        :value="$t('forCaptain')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('status') + ' :'"
        v-else-if="detail_of_mission.status == missionStatus.givenCaptain"
        :value="$t('givenForCaptain')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('status') + ' :'"
        v-else-if="detail_of_mission.status == missionStatus.exhausted"
        :value="$t('exhausted')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('status') + ' :'"
        v-else-if="detail_of_mission.status == missionStatus.cancel"
        :value="$t('statusCanceled')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('status') + ' :'"
        v-else-if="
          detail_of_mission.status == missionStatus.exhaustedWithCaptain
        "
        :value="$t('executedWithCaptain')"
        class="font-bold mt-5"
        disabled
      />
      <vs-input
        :label="$t('status') + ' :'"
        v-else
        :value="$t('canceledWithCaptain')"
        class="mt-5"
        disabled
      />

      <!-- CASH -->
      <vs-input
        :label="$t('cash') + ' :'"
        :value="detail_of_mission.amount"
        class="font-bold mt-5"
        disabled
      />

      <!-- DATE -->
      <vs-input
        :label="$t('rightDate') + ' :'"
        :value="detail_of_mission.due_date"
        class="font-bold mt-5"
        disabled
      />

      <!-- DATE -->
      <vs-input
        :label="$t('dueDate') + ' :'"
        :value="detail_of_mission.execution_date"
        class="font-bold mt-5"
        disabled
      />

      <!-- NOTES -->
      <vs-input
        :label="$t('notes') + ' :'"
        :value="detail_of_mission.notes"
        class="font-bold mt-5"
        disabled
      />
    </div>
    <div class="line my-5"></div>

    <!-- ORDER OF MISSION -->
    <div class="title-page flex justify-between text-center">
      <div>
        <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
        <h3
          class="font-semibold mb-2 ml-3"
          style="display: inline-block; font-size: 1.125rem"
        >
          {{ $t("orderForMission") }}
        </h3>
      </div>
    </div>
    <data-table-view
      :missionId="detail_of_mission.code"
      :data="this.$store.state.dataList.order_for_mission"
      :header="header"
      :body="body"
      :pageName="pageName"
    />
    <div class="line my-5"></div>

    <!-- PAPER OF MISSION -->
    <div class="title-page flex justify-between text-center">
      <div>
        <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
        <h3
          class="font-semibold mb-2 ml-3"
          style="display: inline-block; font-size: 1.125rem"
        >
          {{ $t("paperMonyForMission") }}
        </h3>
      </div>
    </div>
    <data-table-paper-mission
      :data="this.$store.state.dataList.papers_for_mission"
      :header="headerPaper"
      :body="bodyPaper"
      :pageName="pageName"
    />
  </div>

  <div v-else>
    <vs-alert @click="toMissions()">
      <span
      class="text-danger font-bold cursor-pointer" 
      color="danger"
      icon="new_releases"
      >
      لا يوجد مهمة بهذا الكود
    </span>
    <span class="text-primary font-bold cursor-pointer ml-3 inline-block" >العودة الى صفحة المهمات</span>
    </vs-alert>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import DataTableView from "../components/table-components/DataTableOrderMission.vue";
import DataTablePaperMission from "../components/table-components/DataTablePaperMission.vue";
import allStatus from "@/views/status/missionStatus";
import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";
import globalMixin from "@/mixins/mixins.js";

export default {
  mixins: [globalMixin],
  components: {
    DataTableView,
    DataTablePaperMission,
    SpinnerColor,
  },
  data() {
    return {
      res:'',
      id: null,
      missionKey: "password",
      iconMissionKey: false,
      header: [
        "code",
        "dueDate",
        "statusOfOrder",
        "returnStatus",
        "mainSender",
        "receiver",
        "government",
        "area",
        "amountSupplied",
        "stockValue",
        "packagingValue",
        "packagingServicePercentage",
        "insuranceService",
      ],
      body: [
        "code",
        "execution_date",
        "client_status",
        "inventory_status",
        "main_Client",
        "receiver",
        "government",
        "area",
        "cash_amount",
        "stock_service",
        "packaging_service",
        "packaging_service_percentage",
        "protection_service",
      ],
      headerPaper: [
        "numberPaper",
        "type",
        "client",
        "value",
        "rightDate",
        "notes",
        "dateOfRegistration",
        "creatBy",
      ],
      bodyPaper: [
        "number",
        "type",
        "client",
        "value",
        "due_date",
        "notes",
        "created_at",
        "created_by",
      ],
      pageName: "mission/details",
      type: 3,
      missionStatus: allStatus,
      isLoad: false,
      messageApi: "",
      showData: false,
    };
  },
  computed: {
    ...mapGetters("dataList", [
      "detail_of_mission",
      "branch_mission",
      "client_mission",
      "captain_mission",
      "returned",
      "data_mission_print1",
      "data_mission_print2",
      "data_mission_print3",
      "success_api",
      "message",
    ]),
  },
  methods: {
    ...mapActions("dataList", [
      "missionDetails",
      "orderForMission",
      "papersForMission",
      "fetchDataMissionPrint",
      "excelPreview",
      "excelMissionsType",
    ]),
    toMissions() {
      this.$router.push({ path: `/Missions` });
    },
    keyOfMission() {
      this.missionKey = this.missionKey === "password" ? "text" : "password";
      this.iconMissionKey = this.iconMissionKey === false ? true : false;
    },
    async onexport(type = "") {
      this.isLoad = true;
      let obj = {
        id: this.$route.params.id,
        type: type,
      };
      await this.excelPreview(obj);
      this.messageApi = this.message;
      this.isLoad = false;
    },
    toMissionChat() {
      this.$router.push({
        path: "/apps/chat/" + this.detail_of_mission.id,
        query: { type: 3 },
      });
    },
  },
  async created() {
    await this.missionDetails(this.$route.params.id);
    this.showData = true;
    if (this.success_api==1) {
      await this.orderForMission({
        id: this.$route.params.id,
        pageNum: 1,
        itemsPerPage: 10,
      });
      await this.papersForMission(this.$route.params.id);
    }
  
  },
  async mounted() {

  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_missionDetails.scss";
@import "@/assets/scss/vuexy/_loadingTable.scss";
.download-btn {
  .loader {
    border: 2px solid #34ba70;
    border-top: 2px solid #31006f1a;
  }
}

.con-vs-tooltip {
  width: fit-content !important;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";

.all-inputs {
  .vs-con-input-label {
    width: 33.333333333%;
    padding-right: 1.5625rem;
    .vs-con-input {
      width: 100%;
      input {
        font-family: "cairo";
        height: 2.375rem;
        width: 100%;
      }
    }
  }
}
</style>
