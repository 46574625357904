import axios from 'axios'
export default {
    data(){
        return{
            showPndingAjaxLoad: true
        }
    },
    methods: {
        pendingAgax(){
            const myInterceptor = axios.interceptors.request.use((config) => {
                // trigger 'loading=true' event here
                this.showPndingAjaxLoad = true
                return config;
            }, (error) => {
            // trigger 'loading=false' event here
            this.showPndingAjaxLoad = false
            return Promise.reject(error);
            });
        
            axios.interceptors.response.use((response) => {
            // trigger 'loading=false' event here
            this.showPndingAjaxLoad = false
            return response;
            }, (error) => {
            // trigger 'loading=false' event here
            this.showPndingAjaxLoad = false
            return Promise.reject(error);
            });
            axios.interceptors.request.eject(myInterceptor);
        }
    },
    created(){
        this.pendingAgax()
    }
}