<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table ref="table" v-model="selected" noDataText="" :max-items="itemsPerPage" :data="data">

      <template slot="thead">
        <vs-th :key="index" v-for="(head, index) in header" :sort-key="head">{{ $t(head) }}</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody v-if="hasData">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :key="index" v-for="(td, index) in body">
                <p class="product-due_date font-medium truncate">{{ tr[td] }}</p>
                <vs-chip class="product-sender" style="font-size: 0.5625rem;" v-if="tr[td] == null">لا يوجد</vs-chip>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
    </vs-table>
  <empty-data v-if="!hasData" />
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { mapGetters } from 'vuex'
import SpinnerColor from './SpinnerColor2.vue'
import emptyTable from '@/mixins/datatableEmpty.js'
import EmptyData from './EmptyData.vue'

export default {
  name: 'data-table-paper-mission',
  mixins: [emptyTable],
  components: {
    SpinnerColor,
    EmptyData
  },
  props: {
    data: {
      type: Array,
    },
    header: {
      type: Array,
      required: true
    },
    body: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selected: [],
      checkSelect: false,
      itemsPerPage: 10,
      isMounted: false,
      popupActive: false,
      id: '',
    }
  },
  computed: {
    ...mapGetters('dataList', ['feed_count', 'papers_for_mission']),
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    allOrders () {
      return this.data
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.allOrders.length
    }
  },
  watch: {
    data: {
      handler: function() {
        this.tableHasData(this.data)
      },
    }
  },
  methods: {
    ...mapActions('dataList', ["", ""]),
      getOrderStatusColor (status) {
      if (status === 'on_hold')   return 'warning'
      if (status === 'delivered') return 'success'
      if (status === 'canceled')  return 'danger'
      return 'primary'
    },
    triggerClose () {
      this.$refs.popupcontent.$el.children[1].children[1].scrollTop = 0
    }
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_dataTable.scss';
</style>